import React from "react";

import About from "../components/about/about";
import Layout from "../components/layout/layout";
import Seo from "../components/seo";
import { useTranslation } from 'react-i18next';

const NotreMission = () => {
  const {t} = useTranslation();
  return (
    <>
      <Seo
        title={`${t('about_us')}`}
        description="Apprenez-en davantage sur qui nous sommes, ce que nous faisons et pourquoi nous le faisons."
      />
      <Layout>
        <About />
      </Layout>
    </>
  )
}

export default NotreMission
