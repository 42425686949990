import React from "react";
import { useTranslation } from 'react-i18next';

const AboutValue = () => {
  const {t} = useTranslation();
  return (
    <div>
      <div className="font-sans flex flex-col  mx-auto w-full ">
        <div className=" md:hidden my-10">
          <div className=" text-3xl md:text-5xl font-semibold text-center md:text-left leading-loose">
            {t('our_values')}
          </div>
          <div className=" text-gray-500 text-sm md:text-xl text-center md:text-left ">
            {t('the_principles_that_drive_our_team')}
          </div>
        </div>

        <div className="flex flex-col-reverse md:flex-row justify-between items-center w-full space-y-5 ">
          <div className="flex flex-col w-full lg:w-6/12 space-y-10">
            <div className="flex flex-col w-full lg:w-10/12 space-y-5">
            <div className="hidden md:flex text-3xl  md:text-5xl font-semibold text-center md:text-left">
              {t('our_values')}
            </div>
            <div className="hidden md:flex text-gray-500 md:text-xl font-medium text-center md:text-left ">
              {t('the_principles_that_drive_our_team')}
            </div>
            </div>
            
            <div className="list-none my-40 space-y-7 w-full">
              <li className="flex flex-row items-start md:text-lg">
                <div className="w-1/6">
                  <img
                    alt="get started"
                    src="https://d2lxkizvrhu4im.cloudfront.net/icons/smiley_icon_with_bg.svg"
                    className="h-10  lg:h-16"
                  />
                </div>

                <div className="flex flex-col w-5/6 space-y-2 lg:space-y-3 ">
                  <div className="font-semibold  text-lg md:text-xl ">
                    {" "}
                    {t('candidates_first')}
                  </div>
                  <div className="text-sm md:text-base font-normal text-gray-400 lg:w-3/4 leading-loose">
                    {" "}
                    {t('each_member_of_our_team_shares')}
                  </div>
                </div>
              </li>

              <li className="flex flex-row items-start md:text-lg">
                <div className="w-1/6">
                  <img
                    alt="get started"
                    src="https://d2lxkizvrhu4im.cloudfront.net/icons/bulb_icon.svg"
                    className="h-10 lg:h-16"
                  />
                </div>
                <div className="flex flex-col w-5/6 space-y-2 lg:space-y-3">
                  <div className="font-semibold  text-xl md:text-xl">
                    {" "}
                    {t('easy_to_use_solutions')}
                  </div>
                  <div className="text-sm md:text-base font-normal text-gray-400  lg:w-3/4 leading-loose">
                    {" "}
                    {t('identify_the_ideal_candidate')}
                  </div>
                </div>
              </li>
              <li className="flex flex-row items-start md:text-lg">
                <div className="w-1/6">
                  <img
                    alt="get started"
                    src="https://d2lxkizvrhu4im.cloudfront.net/icons/rocket_icon.svg"
                    className="h-10 lg:h-16"
                  />
                </div>
                <div className="flex flex-col w-5/6 space-y-2 lg:space-y-3">
                  <div className="font-semibold  text-xl md:text-xl">
                    {" "}
                    {t('the_most_sophisticated_assessment')} <br className="hidden lg:block"/> {t('in_the_world')}
                  </div>
                  <div className="text-sm md:text-base font-normal text-gray-400 lg:w-3/4 leading-loose">
                    {" "}
                    {t('powerful_innovative_tools')}
                  </div>
                </div>
              </li>

              <li className="flex flex-row items-start md:text-lg">
                <div className="w-1/6">
                  <img
                    alt="get started"
                    src="https://d2lxkizvrhu4im.cloudfront.net/icons/users_2_peoples_icon_with_bg.svg"
                    className="h-10 lg:h-16"
                  />
                </div>
                <div className="flex flex-col w-5/6 space-y-2 lg:space-y-3">
                  <div className="font-semibold  text-xl md:text-xl">
                    {" "}
                    {t('human_expertise')}
                  </div>
                  <div className="text-sm md:text-base font-normal text-gray-400 lg:w-3/4 leading-loose">
                    {" "}
                    {t('because_technology')}
                  </div>
                </div>
              </li>
            </div>
          </div>
         
          <div className="mx-auto w-2/3 lg:w-6/12">
            <img
              alt="get started"
              src="https://d2lxkizvrhu4im.cloudfront.net/images/five_star_screen_under_umbrella.svg"
              className=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutValue;
