import React from "react";
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Link } from "gatsby";

const Work = ({works}) => {
  const breakpoints = useBreakpoint();

  return (
    <div
      className="h-full lg:py-9 border-0 bg-no-repeat bg-cover text-white flex flex-col justify-center mx-auto relative bg-gradient-to-r from-yellow-400 to-yellow-500"
      style={{ backgroundImage: `${!breakpoints.l?`url("https://d2lxkizvrhu4im.cloudfront.net/images/bg3.svg")`:`url("https://d2lxkizvrhu4im.cloudfront.net/images/traveler_bg_mobile.svg")`}` }}
      // style={{ backgroundImage: `url("https://d2lxkizvrhu4im.cloudfront.net/images/bg3.svg")` }}
    >
      <div className="w-3/5 lg:w-5/6 mx-auto my-20 flex flex-col md:flex-row items-center justify-between">
        {
          works.map((work, index) => (
            <div key={index} className="w-full md:w-1/2 mx-auto items-center flex flex-col md:flex-row justify-between space-x-2">
              <div className="w-full md:w-1/4 flex flex-col justify-center items-center">
                <img
                  alt="work images"
                  src={work.image}
                />
              </div>
              <div className="w-full md:w-3/4 ">
                <div className="text-2xl md:text-3xl font-bold text-center flex flex-row justify-center md:justify-start  md:text-left" style={!breakpoints.l?{fontSize:"28px"}:{fontSize:"24px"}}>
                  {work.title}
                </div>
                <div className="font-normal text-center flex md:text-left my-4 text-sm md:text-xl leading-relax opacity-70 pr-5">
                  {work.description}
                </div>
                <div className="text-lg font-bold flex flex-row justify-center md:justify-start ">
                  <a
                    href={work.button.href}
                    className=" text-sm md:text-xl font-semibold"
                    target={'_blank'}
                  >
                    {work.button.text}
                  </a>
                  <img
                    src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_white_icon.svg"
                    alt="arrow img"
                    className="transform rotate-90 w-3 m-0 p-0 "
                  />
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </div>
  );
}

export default Work;
