import React from "react";
import { useBreakpoint } from "gatsby-plugin-breakpoints";

import Subscribe from "../subscribe/subscribe";
import Video from "../video/video";
import AboutValue from "../value/about_values";
import Work from "../work/work";
import Article from "../article/article";
import Started from "../get_started/started_about";
import * as classes from "./about.module.scss";
import { useTranslation } from 'react-i18next';

const About = () => {
  const {t} = useTranslation();
  const breakpoints = useBreakpoint();
  const works = [
    {
      title: `${t('working_at_mereos')}`,
      description: `${t('view_our_offers_and_submit')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/Kids_Studying_from_Home.svg",
      button: {
        text: `${t('send_a_request')}`,
        href: "https://angel.co/company/mereos-1/jobs"
      }
    },
    {
      title: `${t('brand_elements')}`,
      description: `${t('official_logos_badges')}`,
      image: "https://d2lxkizvrhu4im.cloudfront.net/images/girl_sitting_on_couch_under_clouds.svg",
      button: {
        text: `${t('download')}`,
        href: "/zip/Mereos_Media_Kit.zip"
      }
    }
  ];
  
  return (
    <>
      <div className="font-sans">
        <div
          className={`text-white relative  bg-cover lg:bg-contain  bg-no-repeat pb-0 ${classes.banner}`}
          style={{
            backgroundImage: `${
              breakpoints.l
                ? `url("https://d2lxkizvrhu4im.cloudfront.net/images/home_top_banner.svg")`
                : `url("https://d2lxkizvrhu4im.cloudfront.net/images/top_banner_backround.svg")`
            }`,
          }}
        >
          <div className=" flex flex-col justify-center items-center">
            <div className={`w-11/12 lg:w-4/5 mx-auto md:mx-0 lg:mx-auto mt-10 lg:my-14 space-y-3 lg:space-y-7 flex flex-col items-start md:items-center`}>
              <div className={`text-xs md:text-base font-semibold text-left md:text-center opacity-50`}>
                ABOUT US
              </div>
              <div
                className={` text-4xl md:text-6xl text-left md:text-center font-bold w-full lg:leading-snug`}
              >
                <h1>
                  {t('our_mission_is_to_contribute_to')}{" "}
                  <br className="hidden lg:block" /> {t('the_democratization_of_education')}
                </h1>
              </div>

              <div
                className={`text-left md:text-center w-full text-sm lg:text-xl mx-auto leading-loose`}
              >
                {t('we_have_entered_a_new_era')}
                <br className="hidden lg:block" />
                {t('take_online_courses_train_on_youtube')}
                <br className="hidden lg:block" />
                {t('only_problem_Tools_to_assess')}
              </div>
            </div>

            <div
              className={`mb-4 transform rounded-lg w-11/12 md:w-4/5 mx-auto z-10 lg:z-0`}
            >
              <Video
                videoSrcURL="https://www.youtube.com/embed/LAC-ZF4m8-Q"
                videoTitle="Official Music Video on YouTube"
                videoType="online"
                page="about"
              />
            </div>
          </div>
          <div
            className="absolute bg-gray-50 w-full h-28 md:hidden bottom-0"
            style={{
              clipPath: "polygon(0 0, 100% 0%, 100% 100%, 0 100%)",
            }}
          ></div>
        </div>

        {/* Sub container */}
        <div className=" mx-auto my-20  lg:my-32 w-11/12 lg:w-5/6 ">
          {/* Article */}
          <Article />

          {/* Get Started */}
          <div className=" lg:my-32">
            <Started />
          </div>
        </div>

        {/* Work */}
        <div className="lg:my-32">
          <Work works={works}/>
        </div>
        <div className={`mx-auto w-11/12 lg:w-5/6 my-10 lg:my-32`}>
          {/* value */}

          <AboutValue />
        </div>

        {/* Bottom Banner */}
        <div className="">
          <Subscribe />
        </div>
      </div>
    </>
  );
};

export default About;
