import React from "react";
import { useTranslation } from 'react-i18next';

const Started = () => {
  const {t} = useTranslation();
  return (
    <>
      <div className="flex flex-col items-start space-y-6">
        <div className="md:flex justify-start hidden ">
          <img
            alt="rocket_icon"
            src="https://d2lxkizvrhu4im.cloudfront.net/icons/user_in_circle_icon_with_bg.svg"
            className="m-0 w-14"
          />
        </div>
        <div className="flex flex-col-reverse md:flex-row justify-between w-full  ">
          <div className="flex flex-col mx-auto text-left w-full lg:w-1/2 space-y-3 md:space-y-1  ">
            <div className="md:hidden flex justify-start  ">
              <img
                alt="rocket_icon"
                src="https://d2lxkizvrhu4im.cloudfront.net/icons/user_in_circle_icon_with_bg.svg"
                className="m-0 w-14"
              />
            </div>

            <div className="text-2xl font-semibold md:text-5xl  md:leading-snug">
              {t('a_new_approach_for_an_experience')}
            </div>
            <div className="text-gray-500 font-normal lg:font-medium text-sm md:text-xl leading-7 md:leading-7">
              {t('we_are_convinced_that_the_success')}
            </div>
            <div className="text-yellow-500 flex flex-row text-sm md:text-base  font-semibold items-center md:hidden">
              <div> {t('click_here_to_find_out_more')}</div>{" "}
              <img
                src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
                alt="arrow icon"
                className="transform -rotate-90  w-2.5 translate-x-2 m-0 p-0"
              />
            </div>
          </div>

          <div className=" w-11/12 lg:w-1/2 mx-auto flex flex-col items-end">
            <img
              alt="started"
              src="https://d2lxkizvrhu4im.cloudfront.net/images/five_stars_screen_with_people.svg"
              className="h-full"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Started;
