import { Link } from "gatsby";
import React from "react";
import * as classes from "./article.module.scss";
import { useTranslation } from 'react-i18next';

const Article = () => {
  const {t} = useTranslation();
  return (
    <>
      <div>
        <div className="flex flex-row justify-between items-end my-3 lg:mt-12 lg:mb-9">
          <div className={`text-3xl md:text-5xl w-11/12 md:w-1/2 lg:pr-20 text-center md:text-left font-semibold lg:leading-snug`} >
            {t('a_new_way_of_recruiting')}
          </div>
          <div className="text-yellow-500 hidden md:flex  cursor-pointer flex-row items-center space-x-2 my-0">
            <Link to="https://mereos.eu/blog/" className="text-yellow-500  text-xl font-semibold ">
              {t('more_articles')}
            </Link>
            <img
              src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
              className="transform -rotate-90 w-3 m-0 p-0"
              alt="arrow icon"
            />
          </div>
        </div>
        <div className={`flex space-x-4 ${classes.wrapper}`}>
          <div className={` w-full md:w-2/5 flex flex-col`}>
            <Link
              className={`flex flex-col bg-white lg:shadow-lg rounded-xl h-full justify-between ${classes.fullHeight}`}
              to={"https://mereos.eu/blog/limportance-de-lexperience-candidat-lors-dun-recrutement/"}
            >
              <div className={`${classes.height}`}>
                <img alt="article"
                  src="https://d2lxkizvrhu4im.cloudfront.net/images/smilling_girl_with_laptop.jpg"
                  className={`${classes.height}`}
                />
              </div>
              <div className={`flex flex-col h-1/2 pl-3  space-y-3 ${classes.height}`}>
                <div className="flex justify-between pr-3">
                <div className="border-2 border-gray-100 text-gray-400 flex flex-row items-center py-0 rounded-3xl px-5 text-sm font-medium justify-center h-8">
                    {" "}
                    Recrutement 
                  </div>
                  <div className="flex flex-row justify-between items-center text-gray-400 text-sm font-normal space-x-2">
                    <img alt="article"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/clock_grey_icon.svg"
                      className="w-4 my-0"
                    />
                    <span>5 minutes</span>
                  </div>
                </div>
                <div className="font-extrabold text-lg">
                  Expérience Candidat & Recrutement 
                </div>
                <div className="text-sm font-medium text-gray-400 ">
                  Pourquoi une bonne expérience candidat est-elle si importante dans un processus de recrutement ? Comment pouvez-vous faire la différence ? Par où commencer ?
                </div>
                <div className="flex space-x-2">
                  <div className="w-5xl">
                    <img alt="article" src="https://d2lxkizvrhu4im.cloudfront.net/images/Anaïs Chout.png" className="h-10 rounded-3xl"         />
                  </div>
                  <div className="flex flex-col">
                    <div className="text-sm lg:text-base font-semibold">Anaïs Chout</div>
                    <div className="text-xs lg:text-sm  text-gray-400 font-normal">Rédactrice</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>

          <div className="w-3/5 hidden md:flex flex-col  justify-between ">
            <Link
              className={`flex flex-row shadow-lg bg-white rounded-xl overflow-hidden ${classes.height} ${classes.fullHeight}`}
              to={"https://mereos.eu/blog/comment-lia-redefinit-la-maniere-dont-nous-recrutons/"}
            >
              <div className={`w-1/3 ${classes.height}`}>
                <img alt="article"
                  src="https://d2lxkizvrhu4im.cloudfront.net/images/AI_article.jpg"
                  className="h-full w-full my-0"
                />
              </div>
              <div className="flex flex-col w-2/3 space-y-2 m-4">
                <div className="flex justify-between my-2">
                  <div className="border-2 border-gray-100 text-gray-400 flex flex-row items-center py-0 rounded-3xl px-5 text-sm font-medium justify-center h-8">
                    {" "}
                    Recrutement 
                  </div>
                  <div className="flex flex-row justify-between items-center text-gray-400 text-sm font-normal space-x-2 pr-3">
                    <img alt="article"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/clock_grey_icon.svg"
                      className=" w-4 my-0"
                    />
                    <span>8 minutes</span> 
                  </div>
                </div>
                <div className="font-extrabold text-lg">
                  Intelligence Artificielle & Recrutement
                </div>
                <div className="text-sm font-medium text-gray-400  ">
                  Qu’est-ce que l’intelligence artificielle (IA) ? Comment redéfinit-elle la manière dont nous recrutons ? Pourquoi en avez-vous besoin ? 
                </div>
                <div className="flex space-x-2">
                  <div className="w-5xl">
                    <img alt="article" src="https://d2lxkizvrhu4im.cloudfront.net/images/Ruben Levy.png" className="h-10 my-0 rounded-3xl"/>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-base font-semibold">Ruben Levy</div>
                    <div className="text-sm text-gray-400 font-normal">Rédacteur</div>
                  </div>
                </div>
              </div>
            </Link>

            <Link
              className={`flex flex-row shadow-lg bg-white rounded-xl overflow-hidden ${classes.height} ${classes.fullHeight}`}
              to={"https://mereos.eu/blog/comment-empecher-la-fraude-lors-dun-examen-en-ligne/"}
            >
              <div className={`w-1/3   ${classes.height}`}>
                <img alt="article"
                  src="https://d2lxkizvrhu4im.cloudfront.net/images/evaluation_article.jpg"
                  className="h-full w-full  my-0"
                />
              </div>
              <div className="flex w-2/3 flex-col space-y-2 m-4">
                <div className="flex justify-between my-2">
                <div className="border-2 border-gray-100 text-gray-400 flex flex-row items-center py-0 rounded-3xl px-5 text-sm font-medium justify-center h-8">
                    {" "}
                    Recrutement 
                  </div>
                  <div className="flex flex-row justify-between items-center text-gray-400 text-sm font-normal space-x-2 pr-3">
                    <img
                      alt="article"
                      src="https://d2lxkizvrhu4im.cloudfront.net/icons/clock_grey_icon.svg"
                      className="w-4 my-0"
                    />
                     <span>3 minutes</span> 
                  </div>
                </div>
                <div className="font-extrabold text-lg">
                  Évaluation en Ligne & Recrutement 
                </div>
                <div className="text-sm font-medium text-gray-400  ">
                  Pourquoi les candidats trichent-ils ? Comment empêcher la fraude lors d’une évaluation en ligne ? 
                </div>
                <div className="flex space-x-2">
                  <div className="w-5xl">
                    <img
                      alt="article"
                      src="https://d2lxkizvrhu4im.cloudfront.net/images/Anaïs Chout.png"
                      className="h-10 my-0 rounded-3xl"
                    />
                  </div>
                  <div className=" flex flex-col">
                    <div className="text-base font-semibold">Anaïs Chout</div>
                    <div className="text-sm  text-gray-400 font-normal">Rédactrice</div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="text-yellow-500 md:hidden  flex flex-row justify-center my-10 cursor-pointer font-semibold text-sm  items-center space-x-2">
          <span>{t('more_articles')}</span> 
          <img
            src="https://d2lxkizvrhu4im.cloudfront.net/icons/arrow_down_yellow_icon.svg"
            alt="arrow"
            className="w-3 transform -rotate-90 m-0 p-0"
          />
        </div>
      </div>
    </>
  );
};

export default Article;
